<template>
  <b-sidebar
    id="opened-transaction-form"
    :visible="openedTransactionForm"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-transaction-form', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('account.titles.finance.details') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-card class="card-transaction">
        <!-- BODY -->
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-for="status"
              :label="$t('account.titles.transaction.status')"
            >
              <v-select
                id="status"
                v-model="status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptionsTransactions"
                :clearable="false"
                class="w-100"
                :reduce="val => val.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-for="status"
              :label="$t('account.titles.transaction.avatar')"
            >
              <!-- media -->
              <b-media no-body>
                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                      height="150"
                    />
                  </b-link>
                  <!--/ avatar -->

                  <!-- reset -->
                  <b-button
                    v-if="avatar"

                    variant="outline-secondary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="avatar = null"
                  >
                    X
                  </b-button>
                  <!--/ reset -->
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <InitFileManager
                    field="avatar"
                    type="transaction_confirm"
                    :multiple="false"
                    @fm-selected="selectedAvatar"
                  />

                  <b-row
                    v-if="errors && errors.avatar"
                  >
                    <b-col cols="12">
                      <b-form-invalid-feedback :state="false">
                        {{ errors.avatar[0] }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="errors && errors['avatar.type']"
                  >
                    <b-col cols="12">
                      <b-form-invalid-feedback :state="false">
                        {{ errors['avatar.type'][0] }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>

                </b-media-body>
              </b-media>
              <!--/ media -->
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-for="description"
              :label="$t('account.titles.transaction.description')"
            >
              <b-form-textarea
                id="description"
                v-model="description"
                rows="4"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              block
              @click="submit"
            >
              {{ $t('general.save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>

    </template>
  </b-sidebar>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import InitFileManager from '@/components/InitFileManager'

export default {
  components: {
    InitFileManager,
  },
  mixins: [GlobalMixin],
  model: {
    prop: 'openedTransactionForm',
    event: 'update:opened-transaction-form',
  },
  props: {
    openedTransactionForm: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      status: null,
      description: null,
      avatar: null,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-transaction-form', false)
      this.status = null
      this.description = null
      this.avatar = null
    },
    selectedAvatar(data) {
      this.avatar = data
    },
    submit() {
      this.$http.put(`/api/admin/transactions/${this.item.id}`, {
        status: this.status,
        description: this.description,
        avatar: this.avatar,
      })
        .then(() => {
          this.$store.dispatch('validation/clearErrors')
          this.$emit('refetch-data')
          this.$emit('update:opened-transaction-form', false)

          this.status = null
          this.description = null
          this.avatar = null

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-transaction-form {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

<template>

  <div>

    <!-- Filters -->
    <filters
      :status-filter.sync="statusFilter"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('market.table.settings.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('market.table.settings.entries') }}</label>
          </b-col>

        </b-row>

      </div>
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('market.table.settings.empty_text')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(token)="data">
          <feather-icon
            v-b-tooltip.hover.top
            icon="Share2Icon"
            size="16"
            :title="data.item.token"
            variant="outline-primary"
          />
        </template>

        <template #cell(amount)="data">
          <b-badge :variant="data.item.type === '+' ? 'success' : 'danger'">
            {{ data.item.type + $options.filters.moneyFormat(data.item.amount) }}
          </b-badge>
        </template>

        <template #cell(user)="data">
          <div v-text="data.item.user.name" />
          <div v-text="data.item.user.phone" />
          <div v-text="data.item.user.email" />
          <div
            v-if="data.item.user.telegram"
            v-text="`@${data.item.user.telegram}`"
          />
        </template>

        <template #cell(commission)="data">
          {{ $options.filters.moneyFormat(data.item.commission) }}
        </template>

        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status === 'pending'"
            variant="warning"
          >
            {{ $t(`general.statuses.${data.item.status}`) }}
          </b-badge>
          <b-badge
            v-if="data.item.status === 'approved'"
            variant="success"
          >
            {{ $t(`general.statuses.${data.item.status}`) }}
          </b-badge>
          <b-badge
            v-if="data.item.status === 'rejected'"
            variant="danger"
          >
            {{ $t(`general.statuses.${data.item.status}`) }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <span
            v-if="1 === 1 || (data.item.status === 'pending' && data.item.type === '-')"
            class="btn btn-secondary btn-sm"
            @click="openedTransactionForm = true; transaction = data.item"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
              class="align-middle"
            />
          </span>
          <span
            class="btn btn-primary btn-sm ml-1"
            @click="openedTransactionInfo = true; transaction = data.item"
          >
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="align-middle"
            />
          </span>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('market.table.settings.showing') }} {{ dataMeta.from }} {{ $t('market.table.settings.to') }} {{ dataMeta.to }} {{ $t('market.table.settings.of') }} {{ dataMeta.of }} {{ $t('market.table.settings.entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <transaction-form
      :opened-transaction-form.sync="openedTransactionForm"
      :item="transaction | full_freeze"
      @refetch-data="refetchData"
    />

    <transaction-info
      :opened-transaction-info.sync="openedTransactionInfo"
      :item="transaction | full_freeze"
    />
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import TransactionForm from '@/views/admin/transactions/TransactionForm'
import TransactionInfo from '@/views/admin/transactions/TransactionInfo'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import Filters from './Filters.vue'
import useList from './useList'
import storeModule from './storeModule'

export default {
  components: {
    Filters,
    TransactionForm,
    TransactionInfo,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      openedTransactionForm: false,
      openedTransactionInfo: false,
      transaction: {},
      tableColumns: [
        { key: 'token', label: this.$t('market.table.fields.id3'), sortable: false },
        { key: 'user', label: this.$t('market.table.fields.user'), sortable: false },
        { key: 'amount', label: this.$t('market.table.fields.amount'), sortable: false },
        { key: 'commission', label: this.$t('market.table.fields.commission'), sortable: false },
        { key: 'status', label: this.$t('market.table.fields.status'), sortable: false },
        { key: 'created_at', label: this.$t('market.table.fields.date'), sortable: true },
        { key: 'actions', label: this.$t('market.table.fields.actions'), sortable: false },
      ],
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'transactions'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      statusFilter,
    } = useList()

    return {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
